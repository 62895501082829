<template>
  <v-card>
    <v-footer v-bind="localAttrs" :padless="padless" class="pa-0 my-0" height="">
      <v-card flat tile width="100%" class="lighten-1 text-center">
        <v-card-text class="pa-2 hidden-lg-and-down">
          <!-- <v-btn
            v-for="icon in icons"
            :key="icon"
            class="mx-4"
            icon
            hover="ss"
          >
            <v-icon size="24px">
              {{ icon }}
            </v-icon>
          </v-btn>-->

          <v-tooltip top v-for="i in icons"
                :key="i.id">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
              :to="i.route"
                class="mx-4"
                icon
                v-on="on"
                v-bind="attrs"
              >
                <v-icon size="24px">{{ i.icon }}</v-icon>
              </v-btn>
            </template>

            <span>{{i.tip}}</span>
          </v-tooltip>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-text class="pa-2">
          <strong>{{ new Date().getFullYear() }} — SU Lab</strong>
        </v-card-text>
      </v-card>
    </v-footer>
  </v-card>
</template>

<script>
  export default {
    name: 'Footer',
    data: () => ({
      icons: [
        {
          id:0,
          icon:'mdi-home',
          tip:'Home',
          route:'/',

        },
        {
          id:1,
          icon:'mdi-message-bulleted',
          tip:'Topics',
          route:'/topics',

        },
        {
          id:2,
          icon:
        'mdi-account-group',
    
          tip:'Members',
          route:'/members',

        },
        {
          id:3,
          icon:'mdi-book-open',
          tip:'Publications',
          route:'/publications',

        },
        
        {id:4,
          icon:
        'mdi-email',
          tip:'Contact',
          route:'/contact',

        },
        
        
      ],
      items: ['default', 'absolute', 'fixed'],
      padless: false,
      variant: 'default'
    }),
    computed: {
      localAttrs() {
        const attrs = {}

        if (this.variant === 'default') {
          attrs.absolute = false
          attrs.fixed = false
        } else {
          attrs[this.variant] = true
        }
        return attrs
      }
    }
  }
</script>
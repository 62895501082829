module.exports = {
  language: {
    text: '中文',
    link: '链接',
    seemore:"查看更多",
    navbar:{
      home: '主页',
      publications:'研究成果',
      members:'成员',
      introduction:'实验室简介',
      topics:'课题研究',
      contact:'联系方式',
    },
    homepage:{
      overview:"简介",
      overviewContent:"欢迎访问苏春华实验室主页。随着世界对信息技术的依赖度增加，信息安全变得尤为重要。我们对其进行广泛的研究，以适当和安全地使用信息技术。研究课题包括密码学和秘密共享的理论、机动车辆和传感器在内的物联网、人类行为活动的安全管理以及大数据中的隐私问题。",
      currentTopics:"研究课题",
      abstract:"概要",
      keywords:"关键词",
      recentPublished:"论文发表",
      professor:"教授"
    },
    topics:{
      topics:"课题研究",
      abstract:"概要",
      keywords:"关键词",
    },
    members:{
      members:"成员",
      professor:"教授",
      students:"学生",
      headers: [
        {
          text: '年级',
          align: 'start',
          value: 'grade'
        },
        { text: '姓名', value: 'name' },
        { text: '学年', value: 'year' },
      ],
      desserts: [
        {
          grade: '本科',
          year: '3',
          name: '佐藤 知也',
          id: 's1270031',
          dep: '计算机科学与工程'
        },
        {
          grade: '本科',
          year: '3',
          name: '藤田 将志',
          id: 's1270185',
          dep: '计算机科学与工程'
        },
        {
          grade: '本科',
          year: '3',
          name: '内野 晴太',
          id: 's1270214',
          dep: '计算机科学与工程'
        },
        {
          grade: '本科',
          year: '4',
          name: '樫村 康太',
          id: 's1260131',
          dep: '计算机科学与工程'
        },
        {
          grade: '本科',
          year: '4',
          name: '関根 行宏',
          id: 's1250219',
          dep: '计算机科学与工程'
        },
        {
          grade: '本科',
          year: '4',
          name: '呉 子昂',
          id: 's1252001',
          dep: '计算机科学与工程'
        },
        {
          grade: '本科',
          year: '4',
          name: '星 和潤',
          id: 's1260080',
          dep: '计算机科学与工程'
        },
        {
          grade: '本科',
          year: '4',
          name: '藤田 幸希',
          id: 's1260164',
          dep: '计算机科学与工程'
        },
        {
          grade: '硕士',
          year: '1',
          name: '张 臣',
          id: '',
          dep: '计算机与信息系统'
        },
        {
          grade: '硕士',
          year: '2',
          name: '佐野 晶悟',
          id: 'm5241126',
          dep: '计算机与信息系统'
        },
        {
          grade: '硕士',
          year: '1',
          name: '王 子越',
          id: 'm5242121',
          dep: '计算机与信息系统'
        },

        {
          grade: '硕士',
          year: '2',
          name: '増渕 那典',
          id: 'm5241137',
          dep: '计算机与信息系统'
        },
        {
          grade: '博士',
          year: '1',
          name: '连 卓涛',
          id: '',
          dep: '计算机与信息系统'
        },
        {
          grade: '博士',
          year: '1',
          name: '邱 琛',
          id: 'd8222103',
          dep: '计算机与信息系统'
        },
        {
          grade: '博士',
          year: '1',
          name: '肖 鴻治',
          id: 'd8222107',
          dep: '计算机与信息系统'
        },
        {
          grade: '博士',
          year: '1',
          name: '赵 红',
          id: 'd8231109',
          dep: '计算机与信息系统'
        },
        {
          grade: '博士',
          year: '2',
          name: '韓 昭陽',
          id: 'd8212109',
          dep: '计算机与信息系统'
        },
        {
          grade: '博士',
          year: '3',
          name: '野間口 広',
          id: 'd8211102',
          dep: '计算机与信息系统'
        },
        {
          grade: '博士',
          year: '3',
          name: '王 晨',
          id: '访问博士',
          dep: '计算机与信息系统'
        }
      ],
      search:"搜索",
    },
    published:"论文发表",
    contact:{
      contact:"联系方式",
      email:"邮箱",
      mailingAddress:"通讯地址",
      office:"办公室",
      phone:"电话",
      fax:"传真",
      prof:"苏春华教授",
    },

    tutor:{
      basicinfo:"基本信息",
      education:"教授课程",
      research:"研究信息",
      published:"论文发表",
      dissertation:"论文与已发表的作品",
      affiliation:"所属部门",
      labname:"信息安全实验室",
      courseUnder:"本科课程",
      courseL06:"L06 信息安全",
      courseM02:"M02 线性代数 II",
      courseM04:"M04 微分与积分 II",
      courseGraduate:"研究生课程",
      courseCSC01:"CSC01 信息安全",

      educationalBackground:"教育背景，个人经历",
      biographyEducation:"教育经历",
      bg1:"1999-2003 学士, 北京电子科技学院, 中国",
      bg2:"2004-2006 硕士, 九州大学，日本",
      bg3:"2006-2009 工程学博士, 九州大学，日本",
      career:"职业发展",
      cr1:"2008-2010 九州大学/ JSPS研究员 DC2",
      cr2:"2010-2011 新加坡管理大学/ 博士后",
      cr3:"2011-2013 信息通信研究所，A*STA，新加坡/ 科学家 I",
      cr4:"2013-2016 日本科学技术高等研究院/ 助理教授",
      cr5:"2016-2017 大阪大学/ 助理教授",
      cr6:"2017-现在 会津大学/ 副教授",
      currentResearchTheme:"当前研究主题",
      researchThemeContent:"大数据安全、物联网设备安全和隐私技术、密码分析、密码协议",
      affiliatedAcademicSociety:"所属协会",

      name: "苏春华",
      jobtitle:"职称",
      title:"高级副教授",
      intro:"他目前在会津大学计算机科学系担任高级副教授。2009-2011年在新加坡管理大学从事博士后研究，2011-2013年在新加坡信息通信研究所密码与安全部担任研究科学家。2013-2016年在日本科学技术高等研究院信息科学学院担任助理教授。2016-2017年在大阪大学工学研究生院担任助理教授。他的研究兴趣包括密码分析、密码协议、机器学习中的隐私保护技术和物联网安全与隐私。",
      email:"chsu@u-aizu.ac.jp",
      tel:"",
      interest:"",
      hobby:"",
    },


  }
 }


module.exports = {
  language: {
    text: '日本語',
    link: 'リンク',
    seemore:"もっと見る",
    navbar:{
      home: 'ホームページ',
      publications:'研究成果',
      members:'メンバー',
      introduction:'研究室紹介',
      topics:'トピック',
      contact:'お問い合わせ',
    },
    homepage:{
      overview:"はじめに",
      overviewContent:"蘇春華研究室のホームページへようこそ。社会がITへの依存度を高めるにつれて、情報セキュリティの重要性がますます高まっています。 私たちは、暗号や秘密分散などの理論、自動車やセンサーなどのIoT、人間の活動も含めたセキュリティマネジメント、ビッグデータのプライバシーなど、情報セキュリティに関する幅広い研究を通じて、ITを安全に利用できる社会を目指します。",
      currentTopics:"トピック",
      abstract:"まとめ",
      keywords:"キーワード",
      recentPublished:"論文公開",
      professor:"教授"
    },
    topics:{
      topics:"研究トピック",
      abstract:"まとめ",
      keywords:"キーワード",
    },
    members:{
      members:"メンバー",
      professor:"教授",
      students:"学生",
      headers: [
        {
          text: 'グレード',
          align: 'start',
          value: 'grade'
        },
        { text: '名前', value: 'name' },
        { text: '学年', value: 'year' },
      ],
      desserts: [
        {
          grade: '学士',
          year: '3',
          name: '佐藤 知也',
          id: 's1270031',
          dep: '计算机科学与工程'
        },
        {
          grade: '学士',
          year: '3',
          name: '藤田 将志',
          id: 's1270185',
          dep: '计算机科学与工程'
        },
        {
          grade: '学士',
          year: '3',
          name: '内野 晴太',
          id: 's1270214',
          dep: '计算机科学与工程'
        },
        {
          grade: '学士',
          year: '4',
          name: '樫村 康太',
          id: 's1260131',
          dep: '计算机科学与工程'
        },
        {
          grade: '学士',
          year: '4',
          name: '関根 行宏',
          id: 's1250219',
          dep: '计算机科学与工程'
        },
        {
          grade: '学士',
          year: '4',
          name: '呉 子昂',
          id: 's1252001',
          dep: '计算机科学与工程'
        },
        {
          grade: '学士',
          year: '4',
          name: '星 和潤',
          id: 's1260080',
          dep: '计算机科学与工程'
        },
        {
          grade: '学士',
          year: '4',
          name: '藤田 幸希',
          id: 's1260164',
          dep: '计算机科学与工程'
        },
        {
          grade: '修士',
          year: '2',
          name: '佐野 晶悟',
          id: 'm5241126',
          dep: '计算机与信息系统'
        },
        {
          grade: '修士',
          year: '1',
          name: '王 子越',
          id: 'm5242121',
          dep: '计算机与信息系统'
        },
        {
          grade: '博士',
          year: '1',
          name: '连 卓涛',
          id: 'm5232128',
          dep: '计算机与信息系统'
        },
        {
          grade: '修士',
          year: '2',
          name: '増渕 那典',
          id: 'm5241137',
          dep: '计算机与信息系统'
        },
        {
          grade: '博士',
          year: '1',
          name: '邱 琛',
          id: 'd8222103',
          dep: '计算机与信息系统'
        },
        {
          grade: '博士',
          year: '1',
          name: '肖 鴻治',
          id: 'd8222107',
          dep: '计算机与信息系统'
        },
        {
          grade: '博士',
          year: '1',
          name: '赵 红',
          id: 'd8231109',
          dep: '计算机与信息系统'
        },
        {
          grade: '博士',
          year: '2',
          name: '韓 昭陽',
          id: 'd8212109',
          dep: '计算机与信息系统'
        },
        {
          grade: '博士',
          year: '3',
          name: '野間口 広',
          id: 'd8211102',
          dep: '计算机与信息系统'
        },
        {
          grade: '博士',
          year: '3',
          name: '王 晨',
          id: '访问博士',
          dep: '计算机与信息系统'
        }
      ],
      search:"検索",
    },
    published:"論文公開",
    contact:{
      contact:"連絡先情報",
      email:"メール",
      mailingAddress:"郵送先",
      office:"オフィス",
      phone:"電話",
      fax:"ファックス",
      prof:"蘇春華 教授",
    },

    tutor:{
      basicinfo:"基本情報",
      education:"教育",
      research:"研究",
      published:"論文公開",
      dissertation:"主な著書・論文",
      affiliation:"所属",
      labname:"情報セキュリティ学講座",
      courseUnder:"担当科目 - 大学",
      courseL06:"L06 情報セキュリティ",
      courseM02:"M02 線形代数II",
      courseM04:"M04 微積分II",
      courseGraduate:"担当科目 - 大学院",
      courseCSC01:"CSC01 情報セキュリティ",

      educationalBackground:"研究分野",
      biographyEducation:"教育",
      bg1:"1999-2003 学士, 北京電子科技学院大学",
      bg2:"2004-2006 修士, 九州大学",
      bg3:"2006-2009 工学博士, 九州大学",
      career:"仕事",
      cr1:"2008-2010 九州大学/ 学振特別研究員DC2",
      cr2:"2010-2011 シンガポールマネージメント大学 / 博士研究員",
      cr3:"2011-2013 シンガポール科学技術研究庁・インフォコム研究所 / Scientist I",
      cr4:"2013-2016 北陸先端科学技術大学院大学 / 助教",
      cr5:"2016-2017 大阪大学 / 助教",
      cr6:"2017-現在 会津大学/ 准教授",
      currentResearchTheme:"現在の研究課題",
      researchThemeContent:"ビッグデータのプライバシ保護、IoT機器向けのセキュリティとプライバシ、暗号解析、暗号プロトコル",
      affiliatedAcademicSociety:"所属学会",

      name: "蘇春華",
      jobtitle:"職位",
      title:"上級准教授",
      intro:"彼は現在、会津大学コンピュータサイエンス学部の上級准教授です。 2009年から2011年まで、シンガポール経営大学で博士後期研究に従事し、2011年から2013年まで、シンガポール情報通信技術研究所の暗号化およびセキュリティ部門で研究科学者を務めました。 2013年から2016年まで、北陸先端科学技術大学院大学情報科学部助教を務めました。 2016年から2017年まで、大阪大学大学院工学研究科助教を務めました。 彼の研究対象には、暗号解読、暗号化プロトコル、機械学習におけるプライバシー保護テクノロジー、IoTのセキュリティとプライバシーが含まれます。",
      email:"chsu@u-aizu.ac.jp",
      tel:"",
      interest:"",
      hobby:"",
    },


  }
 }
    
module.exports = {
  language: {
    text: 'English',
    link:'Links',
    seemore:"SEE MORE",
    navbar:{
      home: 'Home',
      publications: 'Publications',
      members:'Members',
      introduction:'Introduction',
      topics:'Topics',
      contact:'Contact',
    },
    homepage:{
      overview:"Overview",
      overviewContent:"Welcome to the website of Su laboratory. As the\
      world depends more and more on IT, the growing\
      importance of information security is recognized. We\
      conduct extensive research on information security\
      to use IT appropriately and safely. The topics\
      includes theories of cryptography and secret\
      sharing, IoT including motor vehicles and sensors,\
      security management including human activities, and\
      privacy issues in big data.",
      currentTopics:"Current topics",
      abstract:"Abstract",
      keywords:"Keywords",
      recentPublished:"Recent published works",
      professor:"Professor"
    },
    topics:{
      topics:"Topics",
      abstract:"Abstract",
      keywords:"Keywords",
    },
    members:{
      members:"Members",
      professor:"Professor",
      students:"Students",
      headers: [
        {
          text: 'Grade',
          align: 'start',
          value: 'grade'
        },
        { text: 'Name', value: 'name' },
        { text: 'Year', value: 'year' },
      ],
      desserts: [
        {
          grade: 'Undergraduate',
          year: '3',
          name: '佐藤 知也',
          id: 's1270031',
          dep: 'Computer Science and Engineering'
        },
        {
          grade: 'Undergraduate',
          year: '3',
          name: '藤田 将志',
          id: 's1270185',
          dep: 'Computer Science and Engineering'
        },
        {
          grade: 'Undergraduate',
          year: '3',
          name: '内野 晴太',
          id: 's1270214',
          dep: 'Computer Science and Engineering'
        },
        {
          grade: 'Undergraduate',
          year: '4',
          name: '樫村 康太',
          id: 's1260131',
          dep: 'Computer Science and Engineering'
        },
        {
          grade: 'Undergraduate',
          year: '4',
          name: '関根 行宏',
          id: 's1250219',
          dep: 'Computer Science and Engineering'
        },
        {
          grade: 'Undergraduate',
          year: '4',
          name: '呉 子昂',
          id: 's1252001',
          dep: 'Computer Science and Engineering'
        },
        {
          grade: 'Undergraduate',
          year: '4',
          name: '星 和潤',
          id: 's1260080',
          dep: 'Computer Science and Engineering'
        },
        {
          grade: 'Undergraduate',
          year: '4',
          name: '藤田 幸希',
          id: 's1260164',
          dep: 'Computer Science and Engineering'
        },
        {
          grade: 'Masters',
          year: '1',
          name: '张 臣',
          id: '',
          dep: 'Computer and Infromation System'
        },
        {
          grade: 'Masters',
          year: '2',
          name: '佐野 晶悟',
          id: 'm5241126',
          dep: 'Computer and Infromation System'
        },
        {
          grade: 'Masters',
          year: '1',
          name: '王 子越',
          id: 'm5242121',
          dep: 'Computer and Infromation System'
        },

        {
          grade: 'Masters',
          year: '2',
          name: '増渕 那典',
          id: 'm5241137',
          dep: 'Computer and Infromation System'
        },
        {
          grade: 'PhD',
          year: '1',
          name: '连 卓涛',
          id: '',
          dep: 'Computer and Infromation System'
        },
        {
          grade: 'PhD',
          year: '1',
          name: '邱 琛',
          id: 'd8222103',
          dep: 'Computer and Infromation System'
        },
        {
          grade: 'PhD',
          year: '1',
          name: '肖 鴻治',
          id: 'd8222107',
          dep: 'Computer and Infromation System'
        },
        {
          grade: 'PhD',
          year: '1',
          name: '赵 红',
          id: 'd8231109',
          dep: 'Computer and Infromation System'
        },
        {
          grade: 'PhD',
          year: '2',
          name: '韓 昭陽',
          id: 'd8212109',
          dep: 'Computer and Infromation System'
        },
        {
          grade: 'PhD',
          year: '3',
          name: '野間口 広',
          id: 'd8211102',
          dep: 'Computer and Infromation System'
        },
        {
          grade: 'PhD',
          year: '3',
          name: '王 晨',
          id: 'Visiting PhD student',
          dep: 'Computer and Infromation System'
        }
      ],
      search:"Search",
    },
    published:"Recent published works",
    contact:{
      contact:"Contact information",
      email:"Email",
      mailingAddress:"Mailing Address",
      office:"Office",
      phone:"Phone",
      fax:"Fax",
      prof:"Prof. SU Chunhua",
    },

    tutor:{
      basicinfo:"Basic Info",
      education:"Education",
      research:"Research",
      published:"Published Works",
      dissertation:"Dissertation and Published Works",
      affiliation:"Affiliation",
      labname:"Infromation Security Laboratory",
      courseUnder:"Courses - Undergraduate",
      courseL06:"L06 Information Security",
      courseM02:"M02 Linear Algebra II ",
      courseM04:"M04 Differential and Integral Calculus II",
      courseGraduate:"Courses - Graduate",
      courseCSC01:"CSC01 Information Security",

      educationalBackground:"Educational Background, Biography",
      biographyEducation:"EDUCATION",
      bg1:"1999-2003 Bachelor, Beijing Electronic Science and Technology Institute, China",
      bg2:"2004-2006 Master, Kyushu University, Japan",
      bg3:"2006-2009 Doctor of Engineering, Kyushu University, Japan",
      career:"CAREER",
      cr1:"2008-2010 Kyushu University/ JSPS fellow DC2",
      cr2:"2010-2011 Singapore Management University / postdoctoral fellow",
      cr3:"2011-2013 Institute for Infocomm Research, A*STA, Singapore / Scientist I",
      cr4:"2013-2016 Japan Advanced Institute of Science and Technology / Assistant Professor",
      cr5:"2016-2017 Osaka University / Assistant Professor",
      cr6:"2017- now University of Aizu/ Associate Professor",
      currentResearchTheme:"Current Research Theme",
      researchThemeContent:"Preserving technology in Big Data, Security and Privacy for IoT devices, cryptanalysis, cryptographic protocols",
      affiliatedAcademicSociety:"Affiliated Academic Society",
      name: "SU Chunhua",
      jobtitle:"Title",
      title:"Senior Associate Professor",
      intro:"He is currently working as a Senior Associate Professor in Division of Computer Science, University of Aizu. He has worked as a postdoctoral fellow in Singapore Management University from 2009-2011 and a research scientist in Cryptography & Security Department of the Institute for Infocomm Research, Singapore from 2011-2013. From 2013-2016, he has worked as an Assistant professor in School of Information Science, Japan Advanced Institute of Science and Technology. From 2016-2017, he worked as Assistant Professor in Graduate School of Engineering, Osaka University. His research interests include cryptanalysis, cryptographic protocols, privacy-preserving technologies in machine learning and IoT security & privacy. ",
      email:"chsu@u-aizu.ac.jp",
      tel:"",
      interest:"",
      hobby:"",
    },
    icons: [
      {
        id:0,
        icon:'mdi-home',
        tip:'Home',
        route:'/home',

      },
      {
        id:1,
        icon:'mdi-message-bulleted',
        tip:'Topics',
        route:'/topics',

      },
      {
        id:2,
        icon:
      'mdi-account-group',
  
        tip:'Members',
        route:'/members',

      },
      {
        id:3,
        icon:'mdi-book-open',
        tip:'Publications',
        route:'/publications',

      },
      
      {id:4,
        icon:
      'mdi-email',
        tip:'Contact',
        route:'/contact',

      },
      
      
    ],
  }
 }
<template>
  <v-row class="white outlined ">
    <v-col cols="12" class="ma-0 pa-0 pt-1">
      <v-sheet>
        
        <v-card max-width="500" class="mx-auto pa-1 mr-1 ml-1 mb-1" outlined color="">
                            <v-card-title>
                    {{$t('language.link')}}
                  </v-card-title>
            <v-row dense>

              <v-col v-for="(item, i) in linkItems" :key="i" cols="12" >
                <v-card :color="item.color" dark>
                  <!-- <div class="d-flex flex-no-wrap justify-space-between"> -->
                    <!-- <div>
                      <v-card-title class="text-h5" v-text="item.title"></v-card-title>

                      <v-card-subtitle v-text="item.artist"></v-card-subtitle>

                    </div> -->

                    <!-- <v-avatar class="ma-3" size="125" tile> -->
                      <div style="overflow:hidden;text-align:center;vertical-align:center ">
                      <a :href="item.link" style="vertical-align:middle">
                      <v-img  height="100"  :src="item.src" style="border-radius:2px;vertical-align:middle">
                      </v-img>
                      </a>
                      </div>
                  
                      
                    <!-- </v-avatar> -->
                  <!-- </div> -->
                </v-card>
              </v-col>
            </v-row>
        
        </v-card>
      </v-sheet>
    </v-col>
  </v-row>
</template>
<script>
  export default {
    name: 'Sidelink',
    data: () => ({
            linkItems: [
                              {
          color: '#1F7087',
          src: require('../../assets/links/the-jp.png'),
          link:'https://www.u-aizu.ac.jp/information/the202124.html',

        },
                {
          color: '#952175',
          src: require('../../assets/links/university.png'),
          link:'https://www.u-aizu.ac.jp/en/',
 
        },

                {
          color: '#1F7087',
          src: require('../../assets/links/ieee.png'),
          link:'https://www.ieee.org/',

        },
        {
          color: '#1F7087',
          src: require('../../assets/links/covid.png'),
          link:'https://www.u-aizu.ac.jp/en/information/post-20171176.html',

        },

        
      ],
    })
  }
</script>
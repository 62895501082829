import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)
//使用的是sessionStroage中存储的的lang，默认是中文简体cn
let language = sessionStorage.getItem('lang') || 'en'
const i18n = new VueI18n({
  locale: language,//将i18n的locale设置为你需要的语言
  messages: {
    'cn': require('./config/cn'), //中文简体
    'jp': require('./config/jp'), //中文繁体
    'en': require('./config/en') //英文
  }
})
export default i18n

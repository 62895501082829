<template>
  <v-container class="ma-0 pa-0" style="background: rgb(90, 175, 188)">
    <v-carousel
      cycle
      height="63vh"
      hide-delimiter-background
      show-arrows-on-hover
      class="mt-0 mb-lg-1 hidden-md-and-down"
      continuous
      style="background: rgb(90, 175, 188)"
      progress-color="white"
    >
      <v-carousel-item v-for="(s, i) in slide" :key="i">
        <v-img :src="s" height="100%" eager
      /></v-carousel-item>
    </v-carousel>
    <v-carousel
      height="46vw"
      cycle
      hide-delimiter-background
      hide-delimiters
      show-arrows-on-hover
      class="mt-0 mb-n6 hidden-lg-and-up"
      continuous
      style="background: rgb(90, 175, 188)"
      progress-color="rgb(90, 175, 188)"
    >
      <v-carousel-item v-for="(s, i) in slide" :key="i">
        <v-img :src="s" height="100%" eager
      /></v-carousel-item>
    </v-carousel>

    <v-container class="lighten-5 mb-0">
      <v-row class="hidden-md-and-down">
        <v-col cols="12" lg="9" xl="9" class="ma-0 pa-0">
          <v-sheet min-height="auto" rounded="md">
            <v-container>
              <v-row class>
                <v-col cols="12" class="ma-0 pa-1">
                  <v-sheet min-height="auto">
                    <v-card outlined>
                      <v-card-title>{{
                        $t("language.homepage.overview")
                      }}</v-card-title>

                      <v-row align="center" justify="center" class="pb-1">
                        <v-col cols="6">
                          <v-img
                            class="ml-4"
                            :src="labsrc"
                            style="border: 1px solid black; border-radius: 16px"
                          ></v-img>
                        </v-col>
                        <v-col cols="6" class>
                          <v-card-text class="text-justify pt-0">{{
                            $t("language.homepage.overviewContent")
                          }}</v-card-text>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-sheet>
                </v-col>
              </v-row>
              <v-row class>
                <v-col cols="12 ma-0 pa-1">
                  <v-sheet min-height="auto">
                    <v-card outlined>
                      <v-card-title class="pb-0">{{
                        $t("language.homepage.currentTopics")
                      }}</v-card-title>
                      <v-card-text class="pb-0">
                        <v-container class="pt-0">
                          <!-- style="border:1px solid #008e8e"  -->
                          <v-card
                            v-for="topic in currentTopics"
                            :key="topic.id"
                            class="ma-0 pa-0 mb-1"
                            flat
                          >
                            <v-card-title>
                              <h5
                                class="text-justify"
                                style="color: #008e8e; word-break: keep-all"
                              >
                                {{ topic.title }}
                              </h5>
                            </v-card-title>
                            <v-row align="center" justify="center">
                              <v-col cols="7">
                                <v-card-text class="text-justify mt-0 pt-0">
                                  <strong
                                    >{{
                                      $t("language.homepage.abstract")
                                    }}:</strong
                                  >
                                  <br />
                                  <p class="ma-2">{{ topic.abstract }}</p>
                                </v-card-text>
                                <v-card-text class="mt-0 pt-0">
                                  <strong
                                    >{{
                                      $t("language.homepage.keywords")
                                    }}:</strong
                                  >
                                  <br />
                                  <v-chip
                                    small
                                    class="ma-2"
                                    color="red"
                                    text-color="white"
                                    v-for="keyword in topic.keywords"
                                    :key="keyword.id"
                                    >{{ keyword.text }}</v-chip
                                  >
                                </v-card-text>
                              </v-col>
                              <v-col cols="5">
                                <v-img
                                  class="pa-0 pt-1 mt-2 mb-2"
                                  max-width="600px"
                                  :src="topic.pic"
                                ></v-img>
                              </v-col>
                            </v-row>
                            <v-divider
                              v-if="topic.id !== currentTopics.length - 1"
                            ></v-divider>
                          </v-card>
                        </v-container>
                      </v-card-text>
                      <v-card-actions class="pt-0 mt-0">
                        <v-btn
                          class="hidden-md-and-down"
                          color="deep-purple lighten-2"
                          text
                          to="/topics"
                          >{{ $t("language.seemore") }}</v-btn
                        >
                      </v-card-actions>
                      <!-- <v-card-actions>
                        <v-btn
                          color="deep-purple lighten-2"
                          text
                          to="/news_topics"
                        >See details</v-btn>
                      </v-card-actions>-->
                    </v-card>
                  </v-sheet>
                </v-col>
              </v-row>
              <v-row class>
                <v-col cols="12- ma-0 pa-1">
                  <v-sheet min-height="auto">
                    <v-card outlined>
                      <v-card-title>{{ $t("language.homepage.recentPublished") }}</v-card-title>
                      <v-card-text class="pb-0">
                        <ul v-for="item in dessert" :key="item">
                          <li class="text-justify">{{ item }}</li>
                        </ul>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn
                          color="deep-purple lighten-2"
                          text
                          to="/publications"
                          >{{ $t("language.seemore") }}</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </v-sheet>
                </v-col>
              </v-row>
            </v-container>
          </v-sheet>
        </v-col>

        <v-col cols="3" class="ma-0 pa-0 hidden-md-and-down">
          <v-sheet min-height="auto" rounded="md">
            <v-container>
              <v-row>
                <v-col cols="12" class="ma-0 pa-1">
                  <v-sheet min-height="auto">
                    <!-- 导师介绍 -->
                    <v-card :loading="loading" class="ma-auto" outlined>
                      <v-row
                        class="ma-0 pl-2"
                        style="border-radius: 50%"
                        align="center"
                        height="150"
                        width="150"
                      >
                        <v-img
                          max-height="150"
                          max-width="150"
                          class="mt-2 mb-2"
                          :src="this.portraitSrc"
                          style="border-radius: 50%; border: 5px solid #008e8e"
                        ></v-img>
                      </v-row>

                      <v-card-title class="pb-1">{{
                        $t("language.tutor.name")
                      }}</v-card-title>

                      <v-card-text>
                        <!-- <v-row align="center" class="mx-0">
                          <v-rating
                            :value="4.5"
                            color="amber"
                            dense
                            half-increments
                            readonly
                            size="14"
                          ></v-rating>

                          <div class="grey--text ms-4">4.5 (413)</div>
                        </v-row>-->

                        <div class="text-subtitle-2" style="color: #008e8e">
                          {{ $t("language.tutor.title") }}
                        </div>
                      </v-card-text>

                      <v-divider class="mx-4"></v-divider>
                      <v-card-text class="pb-0">
                        <p
                          class="text-justify mb-1"
                          style="
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 10;
                            -webkit-box-orient: vertical;
                            color: black;
                          "
                        >
                          {{ $t("language.tutor.intro") }}
                        </p>
                      </v-card-text>

                      <v-card-actions class="pt-0 mt-0">
                        <v-btn
                          color="deep-purple lighten-2"
                          text
                          to="/members"
                          >{{ $t("language.seemore") }}</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </v-sheet>
                </v-col>
              </v-row>
              <Sidelink></Sidelink>
            </v-container>
          </v-sheet>
        </v-col>
      </v-row>

      <v-row class="hidden-lg-and-up">
        <v-col cols="12" lg="9" xl="9" class="ma-0 pa-0">
          <v-sheet min-height="auto" rounded="md">
            <v-container>
              <v-row class>
                <v-col cols="12" class="ma-0 pa-1">
                  <v-sheet min-height="auto">
                    <v-card outlined>
                      <v-card-title>{{
                        $t("language.homepage.overview")
                      }}</v-card-title>

                      <v-row>
                        <v-col cols="12">
                          <v-img
                            class="ml-8 mr-8"
                            :src="labsrc"
                            style="border: 1px solid black; border-radius: 16px"
                          ></v-img>
                        </v-col>
                        <v-col cols="12" class>
                          <v-card-text class="text-justify pt-0">{{
                            $t("language.homepage.overviewContent")
                          }}</v-card-text>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-sheet>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="ma-0 pa-1">
                  <v-sheet min-height="auto">
                    <!-- 导师介绍 -->
                    <v-card :loading="loading" class="ma-auto" outlined>
                      <v-card-title class="pb-0">{{
                        $t("language.homepage.professor")
                      }}</v-card-title>

                      <v-row
                        class="ma-0 pl-2"
                        style="border-radius: 50%"
                        align="center"
                        height="150"
                        width="150"
                        justify="center"
                      >
                        <v-img
                          max-height="150"
                          max-width="150"
                          class="mt-2 mb-2"
                          :src="this.portraitSrc"
                          style="border-radius: 50%; border: 5px solid #008e8e"
                        ></v-img>
                      </v-row>

                      <v-card-title class="pb-1">{{
                        $t("language.tutor.name")
                      }}</v-card-title>

                      <v-card-text>
                        <!-- <v-row align="center" class="mx-0">
                          <v-rating
                            :value="4.5"
                            color="amber"
                            dense
                            half-increments
                            readonly
                            size="14"
                          ></v-rating>

                          <div class="grey--text ms-4">4.5 (413)</div>
                        </v-row>-->

                        <div class="text-subtitle-2" style="color: #008e8e">
                          {{ $t("language.tutor.title") }}
                        </div>
                      </v-card-text>

                      <v-divider class="mx-4"></v-divider>
                      <v-card-text class="pb-0">
                        <p
                          class="text-justify mb-1"
                          style="
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 10;
                            -webkit-box-orient: vertical;
                            color: black;
                          "
                        >
                          {{ $t("language.tutor.intro") }}
                        </p>
                      </v-card-text>

                      <v-card-actions class="pt-0 mt-0">
                        <v-btn
                          color="deep-purple lighten-2"
                          text
                          to="/members"
                          >{{ $t("language.seemore") }}</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </v-sheet>
                </v-col>
              </v-row>

              <v-row class>
                <v-col cols="12 ma-0 pa-1">
                  <v-sheet min-height="auto">
                    <v-card outlined>
                      <v-card-title class="pb-0">{{
                        $t("language.homepage.currentTopics")
                      }}</v-card-title>
                      <v-card-text class="pb-0">
                        <v-container class="pt-0 pl-0 pr-0">
                          <!-- style="border:1px solid #008e8e"  -->
                          <v-card
                            v-for="topic in currentTopics"
                            :key="topic.id"
                            class="ma-0 pa-0 mb-1"
                            flat
                          >
                            <v-card-title>
                              <h5
                                class="text-justify"
                                style="color: #008e8e; word-break: keep-all"
                              >
                                {{ topic.title }}
                              </h5>
                            </v-card-title>

                            <v-row align="center" justify="center">
                              <v-col cols="12">
                                <v-img
                                  class="pa-0 pt-1 mt-2 mb-2"
                                  max-width="600px"
                                  style="margin: auto"
                                  :src="topic.pic"
                                ></v-img>
                                <v-col cols="12">
                                  <v-card-text class="text-justify mt-0 pt-0">
                                    <strong
                                      >{{
                                        $t("language.homepage.abstract")
                                      }}:</strong
                                    >
                                    <br />
                                    <p class="ma-2">{{ topic.abstract }}</p>
                                  </v-card-text>

                                  <v-card-text class="mt-0 pt-0">
                                    <strong
                                      >{{
                                        $t("language.homepage.keywords")
                                      }}:</strong
                                    >
                                    <br />
                                    <v-chip
                                      small
                                      class="ma-2"
                                      color="red"
                                      text-color="white"
                                      v-for="keyword in topic.keywords"
                                      :key="keyword.id"
                                      >{{ keyword.text }}</v-chip
                                    >
                                  </v-card-text>
                                </v-col>
                              </v-col>
                            </v-row>
                            <v-divider
                              v-if="topic.id !== currentTopics.length - 1"
                            ></v-divider>
                          </v-card>
                        </v-container>
                      </v-card-text>
                      <v-card-actions class="pt-0 mt-0">
                        <v-btn color="deep-purple lighten-2" text to="/topics"
                          >{{ $t("language.seemore") }}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-sheet>
                </v-col>
              </v-row>
              <v-row class>
                <v-col cols="12- ma-0 pa-1">
                  <v-sheet min-height="auto">
                    <v-card outlined>
                      <v-card-title>{{
                        $t("language.homepage.recentPublished")
                      }}</v-card-title>
                      <v-card-text class="pb-0">
                        <ul v-for="item in dessert" :key="item">
                          <li class="text-justify">{{ item }}</li>
                        </ul>
                      </v-card-text>
                      <v-card-actions>
                        <a
                          target="_blank"
                          href="https://www.u-aizu.ac.jp/research/facultylist/endetail?cd=90107#db-dissertation"
                        >
                          <v-btn color="deep-purple lighten-2" text>
                            {{ $t("language.seemore") }}
                          </v-btn></a
                        >
                      </v-card-actions>
                    </v-card>
                  </v-sheet>
                </v-col>
              </v-row>
            </v-container>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import Sidelink from "../subcomponents/Sidelinks";

export default {
  name: "Home",
  components: {
    Sidelink,
  },

  data: () => ({
    labsrc: require("../../assets/lab.png"),
    loading: false,

    selection: 1,
    colors: [
      "indigo",
      "warning",
      "pink darken-2",
      "red lighten-1",
      "deep-purple accent-4",
    ],
    slide: [
      require("/src/assets/carousel/university.jpg"),
      require("/src/assets/carousel/tsurugajo.jpeg"),

      // require("/src/assets/carousel/university.jpeg"),
      require("/src/assets/carousel/school.jpeg"),
    ],
    dessert: [
      "Lingjun Zhao, Huakun Huang, Chunhua Su, Shuxue Ding, Huawei Huang, Zhiyuan Tan, Zhenni Li. “Block-Sparse Coding-Based Machine Learning Approach for Dependable Device-Free Localization in IoT Environment”,IEEE Internet Things J, Vol. 8, Issue. 5, pp.3211-3223, 2021.",
      "Mohammad Saiful Islam Mamun, Atsuko Miyaji, Rongxing Lu, Chunhua Su. “A lightweight multi-party authentication in insecure reader-server channel in RFID-based IoT”, Peer Peer Netw. Appl, Vol. 14, Issue. 2, pp.708-721, 2021",
      "Liming Fang, Xinyu Yun, Changchun Yin, Weiping Ding, Lu Zhou, Zhe Liu, Chunhua Su. “ANCS: Automatic NXDomain Classification System Based on Incremental Fuzzy Rough Sets Machine Learning”, IEEE Trans. Fuzzy Syst, Vol. 29, Issue. 4, pp.742-756, 2021.   ",
      "Lu Zhou, Chunpeng Ge, Chunhua Su. “A privacy preserving two-factor authentication protocol for the Bitcoin SPV nodes”, Sci. China Inf. Sci, Vol. 63, Issue. 3, In Press, 2020.",
      "Guangquan Xu, Xiaotong Li, Litao Jiao, Weizhe Wang, Ao Liu, Chunhua Su, Xi Zheng, Shaoying Liu, Xiaochun Cheng. “BAGKD: A Batch Authentication and Group Key Distribution Protocol for VANETs”, IEEE Commun. Mag, Vol. 58, Issue. 7, pp.35-41, 2020.",
      "Na Ruan, Ruoyu Deng, Chunhua Su“GADM Manual fake review detection for O2O commercial platforms”, Comput. Secur. , 88, In Press, 2020.",
    ],
    currentTopics: [
      {
        id: 0,
        title:
          "Secure Data Aggregation Scheme for 5G and Beyond defined Space-Terrestrial Integrated Networks",
        abstract:
          "We propose a trustworthiness based secure data aggregation scheme with low latency for isolated terminals in 5G and beyond defined STINs. The trustworthiness of the isolated terminals is evaluated by the satellites and the data centers to increase the reliability of the aggregated data. A lightweight trusted special channel establishment method between an isolated terminal and a data center is presented. ",
        keywords: [
          {
            id: 0,
            text: "secure data aggregation",
          },
          {
            id: 1,
            text: "trustworthiness evaluation",
          },
          {
            id: 2,
            text: "space-terrestrial integrated networks",
          },
        ],
        pic: require("../../assets/topics/topic01.png"),
      },
      // {
      //   id: 1,
      //   title:
      //     'COFEL: Communication-Efficient and Optimized Federated Learning with Local Differential Privacy',
      //   abstract:
      //     'We proposed COFEL, a novel federated learning system which can both reduce the communication time by layer-based parameter selection and enhance the privacy protection by applying local differential privacy mechanism on the selected parameters. We present COFEL-AVG algorithm for global aggregation and designed layer-based parameter selection method which can select the valuable parameters for global aggregation to optimize the communication and training process. And it can reduce the update data size as only selected part will be transferred. ',
      //   keywords: [
      //     {
      //       id: 0,
      //       text: 'federated learning'
      //     },
      //     {
      //       id: 1,
      //       text: 'differential privacy'
      //     },
      //     {
      //       id: 2,
      //       text: 'non-IID data'
      //     }
      //   ],
      //   pic: require('../../assets/topics/cofel.png')
      // }
    ],
    portraitSrc: require("../../assets/portrait/SUChunhua.png"),

    methods: {},
  }),
};
</script>
<style scoped>
a {
  text-decoration: none;
}
a:link {
  text-decoration: none;
}
a:active {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
a:visited {
  text-decoration: none;
}
</style>
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import i18n from '@/language'
import router from './router'
import globalVariable from './global/Global.vue'


Vue.prototype.GLOBAL = globalVariable; 
Vue.config.productionTip = false


new Vue({
  vuetify,
  i18n,
  el: '#app',
  router,
  render: h => h(App)
})
<template>
  <v-app>
    <!-- <Navbar></Navbar> -->
    <v-main class="lighten-3 mt-2 pt-lg-16" style="background:rgb(90,175,188);">
      <Navbar ></Navbar>
      <!-- <Mobile class="hidden-lg-and-up">
      </Mobile> -->
      
      <v-container class="mb-0 pb-1">
        <v-row class="ma-0 pa-0">
          <v-col
            cols="12"
            sm="12"
            class="mt-lg-5 pa-0"
            style="align-self:center"
          >
            <v-row align="center" justify="center">

              <v-sheet width="100vw" rounded="md" class="mt-1 mb-4 hidden-lg-and-up">
                <router-view min-height="70vh" rounded="md"></router-view>
              </v-sheet>
                            <v-sheet width="68vw" rounded="md" class="mt-1 mb-4 hidden-md-and-down">
                <router-view min-height="70vh" rounded="md"></router-view>
              </v-sheet>
            
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <Footer></Footer>
  </v-app>
</template>

<script>
  import Navbar from './components/subcomponents/Navbar'
  // import Mobile from './components/pages/Mobile'
  import Footer from './components/subcomponents/Footer'

  export default {
    components: {
      Navbar,
      Footer,
      // Mobile,
    },
    data: () => ({
            drawer: false,
      group: null,
    }),
    mounted() {
      // var temp = this.$route.path
      // console.log(temp)
      // if (this._isMobile()) {
      //   // alert('手机端')
      //   // console.log(temp)
      //   // alert('View the complete information on PC')
      //   // if (temp !== '/mobile_index') {
      //   //   this.$router.push('/mobile_index')
      //   // }
      // } else {
      //   console.log(temp)
      //   this.$router.push('/home')
      //   // if (temp !== '/home') {
        // console.log(this.$route)
      // this.$router.push('/home')
      //   // }
      // }
    },
    methods: {
      //App.vue
      //App.vue
      _isMobile() {
        let flag = navigator.userAgent.match(
          /(phone|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
        )
        return flag
      }
    }
  }
</script>
import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../components/pages/Home.vue'
import Topics from '../components/pages/Topics.vue'
import Publications from '../components/pages/Publications.vue'
import Members from '../components/pages/Members.vue'
import Contact from '../components/pages/Contact.vue'
// import Mobile from '../components/pages/Mobile.vue'
// import Mobilecontact from '../components/pages/Mobilecontact'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  // {
  //   path: '/mobile_index',
  //   name: 'Mobile',
  //   component: Mobile
  // },
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/publications',
    name: 'Publications',
    component: Publications
  },
  {
    path: '/members',
    name: 'Members',
    component: Members
  },
  {
    path: '/topics',
    name: 'Topics',
    component: Topics
  },

  // {
  //   path: '/mobile-contact',
  //   name: 'Mobilecontact',
  //   component: Mobilecontact,
  // },

  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  mode:"history",
  routes,
    scrollBehavior(to,from,saveTop){ if(saveTop){ return saveTop; }else{ return {x:0,y:0} } },
})

export default router

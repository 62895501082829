<template>
  <v-container class="ma-0 pa-0" style="background: rgb(90, 175, 188)">
    <v-row class="pa-0 ma-0">
      <v-col cols="12" lg="9" xl="9" class="ma-0 pa-0 pr-0">
        <v-container white class="ma-0 pa-1" style="min-height: 86vh">
          <v-card outlined>
            <v-card-title>{{ $t("language.contact.contact") }}</v-card-title>

            <v-card outlined class="ma-3">
              <v-card-title class="light" style="color: #008e8e"
                >{{ $t("language.contact.prof") }}</v-card-title
              >
              <v-card-text>
                <div>
                  <h4>{{ $t("language.contact.email") }}:</h4>

                  <a href="mailto:chsu@u-aizu.ac.jp">{{
                    $t("language.tutor.email")
                  }}</a>
                </div>
                <v-divider class="mt-2 mb-2"></v-divider>
                <h4>{{ $t("language.contact.mailingAddress") }}:</h4>
                <div>
                  <strong>{{ $t("language.contact.office") }}:</strong>
                </div>
                <div>
                  347A, Division of Computer Science, University of Aizu,
                  Aizuwakamatsu city, Fukushima, Japan 965-8580.
                </div>
                <div>
                  <strong>{{ $t("language.contact.phone") }}:</strong>
                </div>
                <div>0242-37-2614</div>
                <div>
                  <strong>{{ $t("language.contact.fax") }}:</strong>
                </div>
                <div>0242-37-2734</div>
              </v-card-text>
            </v-card>

            <v-img :src="bgsrc" class="ma-3"></v-img>

          </v-card>
        </v-container>
      </v-col>

      <v-col cols="3" class="hidden-md-and-down">
        <Sidelink></Sidelink>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Sidelink from "../subcomponents/Sidelinks";
export default {
  name: "Contact",
  components: {
    Sidelink,
  },
  data: () => ({
    bgsrc: require("../../assets/aizu.jpeg"),
  }),
};
</script>
<style scoped>
</style>
<template>
  <v-container class="ma-0 pa-0" style="background: rgb(90, 175, 188)">
    <v-row class="pa-0 ma-0">
      <v-col cols="12" lg="9" xl="9" class="ma-0 pa-0 pr-0">
        <v-container white class="ma-0 pa-1">
          <v-card outlined>
            <v-card-title>{{ $t("language.topics.topics") }}</v-card-title>

            <v-container class="pt-0">
              <!-- style="border:1px solid #008e8e"  -->
              <v-card
                v-for="topic in currentTopics"
                :key="topic.id"
                class="ma-0 pa-0 mb-1"
                flat
              >
                <v-card-title>
                  <h5
                    class="text-justify"
                    style="color: #008e8e; word-break: keep-all"
                  >
                    {{ topic.title }}
                  </h5>
                </v-card-title>
                <v-row align="center" justify="center">
                  <v-col cols="12" lg="5" xl="5">
                    <v-img
                      class="pa-0 pt-1 mt-2 mb-2"
                      max-width="600px"
                      style="margin: auto"
                      :src="topic.pic"
                    ></v-img>
                  </v-col>
                  <v-col cols="12" lg="7" xl="7">
                    <v-card-text class="text-justify mt-0 pt-0">
                      <strong>{{ $t("language.topics.abstract") }}:</strong>
                      <br />
                      <p class="ma-2">{{ topic.abstract }}</p>
                    </v-card-text>
                    <v-card-text class="mt-0 pt-0">
                      <strong>{{ $t("language.topics.keywords") }}:</strong>
                      <br />
                      <v-chip
                        small
                        class="ma-2"
                        color="red"
                        text-color="white"
                        v-for="keyword in topic.keywords"
                        :key="keyword.id"
                        >{{ keyword.text }}</v-chip
                      >
                    </v-card-text>
                  </v-col>
                </v-row>
                <v-divider
                  v-if="topic.id !== currentTopics.length - 1"
                ></v-divider>
              </v-card>
            </v-container>

            <!-- <v-divider class="ma-5 my-0 pa-0"></v-divider> -->
          </v-card>
        </v-container>
      </v-col>

      <v-col cols="3" class="hidden-md-and-down">
        <Sidelink></Sidelink>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Sidelink from "../subcomponents/Sidelinks";
export default {
  name: "Intro",
  components: {
    Sidelink,
  },
  data: () => ({
    currentTopics: [
      {
        id: 0,
        title:
          "Secure Data Aggregation Scheme for 5G and Beyond defined Space-Terrestrial Integrated Networks",
        abstract:
          "We propose a trustworthiness based secure data aggregation scheme with low latency for isolated terminals in 5G and beyond defined STINs. The trustworthiness of the isolated terminals is evaluated by the satellites and the data centers to increase the reliability of the aggregated data. A lightweight trusted special channel establishment method between an isolated terminal and a data center is presented. ",
        keywords: [
          {
            id: 0,
            text: "secure data aggregation",
          },
          {
            id: 1,
            text: "trustworthiness evaluation",
          },
          {
            id: 2,
            text: "space-terrestrial integrated networks",
          },
        ],
        pic: require("../../assets/topics/topic01.png"),
      },

      {
        id: 1,

        //请填写以下部分
        title:
          "An Adaptive Physical Layer Key Extraction Scheme for Smart Homes",
        abstract:
          "We propose an adaptive physical layer key extraction scheme for smart homes by making use of the Received Signal Strength (RSS). Specifically, in our scheme, two smart home devices collect the RSS measurements by probing with each other. Then, the collected measurements are quantized by running the proposed Self-adaptive Lossless Quantization algorithm. Completing these operations, the two smart home devices can obtain the secret key. In our scheme, we design the self-adaptive lossless quantization algorithm to improve the key generation rate. ",
        keywords: [
          {
            id: 0,
            text: "self-adaptive lossless quantization",
          },
          {
            id: 1,
            text: "key generation",
          },
          {
            id: 2,
            text: "physical layer",
          },
          {
            id: 3,
            text: "smart home devices",
          },
        ],
        link: "https://ieeexplore.ieee.org/document/8887371",
        //请填写以上部分

        pic: require("../../assets/topics/01.jpeg"),
      },

      {
        id: 2,
        title:
          "COFEL: Communication-Efficient and Optimized Federated Learning with Local Differential Privacy",
        abstract:
          "We proposed COFEL, a novel federated learning system which can both reduce the communication time by layer-based parameter selection and enhance the privacy protection by applying local differential privacy mechanism on the selected parameters. We present COFEL-AVG algorithm for global aggregation and designed layer-based parameter selection method which can select the valuable parameters for global aggregation to optimize the communication and training process. And it can reduce the update data size as only selected part will be transferred. ",
        keywords: [
          {
            id: 0,
            text: "federated learning",
          },
          {
            id: 1,
            text: "differential privacy",
          },
          {
            id: 2,
            text: "non-IID data",
          },
        ],
        pic: require("../../assets/topics/cofel.png"),
      },
    ],
  }),
};
</script>
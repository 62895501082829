<template>
<v-container class="ma-0 pa-0">
  <v-app-bar app color="white" flat height="90" class="hidden-md-and-down ">
    <!-- <v-avatar class="hidden-sm-and-down" color="grey darken-1 shrink" size="32">
    </v-avatar>-->
    <a href="https://www.u-aizu.ac.jp/en/" target="_blank">
      <v-img
        class="hidden-md-and-down"
        :src="this.logoUniSrc"
        max-height="70"
        width="120"
      ></v-img>
    </a>
    <router-link to="/" class="pa-0 ma-0">
      <v-img
        class="hidden-md-and-down pa-0 ma-0"
        :src="this.logoLabSrc"
        max-height="70"
        width="120"
      ></v-img>
    </router-link>

    <v-tabs centered  class="pl-lg-12 pr-lg-12 hidden-md-and-down" style="" color="rgb(34,156,166)" grow >
      <v-tab to="/">{{$t('language.navbar.home')}}</v-tab>
      <v-tab  to="/topics">{{$t('language.navbar.topics')}}</v-tab>
      <v-tab  to="/members">{{$t('language.navbar.members')}}</v-tab>
      <v-tab to="/publications">{{$t('language.navbar.publications')}}</v-tab>
      
      <v-tab to="/contact">{{$t('language.navbar.contact')}}</v-tab>
    </v-tabs>

  <v-card width="140" class="hidden-md-and-down"></v-card>
    <v-card width="200" class="hidden-md-and-down ma-0 pa-0">
      <v-select
        :items="items"
        label="Language"
        v-model="defaultValue"
        outlined
        hide-details="auto"
        @input="changeLang"
      ></v-select>
    </v-card>
    <!-- <v-avatar class="hidden-sm-and-down" color="grey darken-1 shrink" width="360"></v-avatar> -->
  </v-app-bar>

  <v-app-bar app color="white"  height="70" class="hidden-lg-and-up">
    <!-- <v-avatar class="hidden-sm-and-down" color="grey darken-1 shrink" size="32">
    </v-avatar>-->
<router-link to="/" class="pa-0 ma-0 hidden-sm-and-down">
      <v-img
        class="hidden-sm-and-down pa-0 ma-0"
        :src="this.logoLabSrc"
        max-height="70"
        width="120"
      ></v-img>
    </router-link>

 <v-card width="60" class="hidden-sm-and-down ma-0 pa-0"></v-card>

    <v-tabs centered class="pl-lg-0 pr-lg-0" style="font-size:14px" grow color="rgb(34,156,166)">
      <v-tab class="pa-0 ma-0" to="/">{{$t('language.navbar.home')}}</v-tab>
<v-tab class="pa-0 ma-0" to="/topics">{{$t('language.navbar.topics')}}</v-tab>
      <v-tab class="pa-0 ma-0" to="/members">{{$t('language.navbar.members')}}</v-tab>
      <v-tab class="pa-0 ma-0 hidden-sm-and-down" to="/publications">{{$t('language.navbar.publications')}}</v-tab> 
      
      <v-tab class="pa-0 ma-0" to="/contact">{{$t('language.navbar.contact')}}</v-tab>
    </v-tabs>

 <v-card width="180" class="hidden-sm-and-down ma-0 pa-0">
      <v-select
        :items="items"
        label="Language"
        v-model="defaultValue"
        outlined
        hide-details="auto"
        @input="changeLang"
      ></v-select>
    </v-card>
    <!-- <v-avatar class="hidden-sm-and-down" color="grey darken-1 shrink" width="360"></v-avatar> -->
  </v-app-bar>

  </v-container>
</template>

<script>
  export default {
    name: 'Navbar',

    data: () => ({

      logoLabSrc: require('../../assets/logo/logo-lab.png'),
      logoUniSrc: require('../../assets/logo/logo-university.png'),
      defaultValue: 'English',
      // items: ['English', '中文', '日本語']
      items: ['English', '中文']
    }),
    beforeMount() {
      var temp = sessionStorage.getItem('lang') || 'en'
      this.defaultValue =
        temp == 'cn' ? '中文' : temp == 'jp' ? '日本語' : 'English'
      // console.log(this.defaultValue)
    },
    methods: {
      changeLang(val) {
        this.defaultValue = val
        val = val == 'English' ? 'en' : (val = val == '中文' ? 'cn' : 'jp')
        sessionStorage.setItem('lang', val)
        this.$i18n.locale = val //this.$i18n的local进行语言切换
      }
    }
  }
</script>

<style>
  .v-tab {
    font-size: 1.2em;
    padding: 0 30px 0 30px;
 
text-transform: none !important;

  }
.v-slide-group__prev {
display: none !important;
}
</style>
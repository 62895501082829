<template>
  <v-container class="ma-0 pa-0" style="background: rgb(90, 175, 188)">
    <v-row class="pa-0 ma-0">
      <v-col cols="12" lg="9" xl="9" class="ma-0 pa-0 pr-0">
        <v-container white class="ma-0 pa-1" style="min-height: 86vh">
          <v-card outlined color>
            <v-card-title>{{ $t("language.published") }}</v-card-title>
            <v-card-text>
              <ul>
                <li v-for="item in desserts" :key="item" class="text-justify">
                  {{ item }}
                </li>
              </ul>
            </v-card-text>
            <v-card-actions>
              <a
                target="_blank"
                href="https://www.u-aizu.ac.jp/research/facultylist/endetail?cd=90107#db-dissertation"
              >
                <v-btn color="deep-purple lighten-2" text>
                  {{ $t("language.seemore") }}
                </v-btn></a
              >
            </v-card-actions>
          </v-card>
        </v-container>
      </v-col>
      <v-col cols="3" class="hidden-md-and-down">
        <Sidelink></Sidelink>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Sidelink from "../subcomponents/Sidelinks";
export default {
  name: "Publications",
  components: {
    Sidelink,
  },
  data: () => ({
    desserts: [
      "Lingjun Zhao, Huakun Huang, Chunhua Su, Shuxue Ding, Huawei Huang, Zhiyuan Tan, Zhenni Li. “Block-Sparse Coding-Based Machine Learning Approach for Dependable Device-Free Localization in IoT Environment”,IEEE Internet Things J, Vol. 8, Issue. 5, pp.3211-3223, 2021.",
      "Mohammad Saiful Islam Mamun, Atsuko Miyaji, Rongxing Lu, Chunhua Su. “A lightweight multi-party authentication in insecure reader-server channel in RFID-based IoT”, Peer Peer Netw. Appl, Vol. 14, Issue. 2, pp.708-721, 2021",
      "Liming Fang, Xinyu Yun, Changchun Yin, Weiping Ding, Lu Zhou, Zhe Liu, Chunhua Su. “ANCS: Automatic NXDomain Classification System Based on Incremental Fuzzy Rough Sets Machine Learning”, IEEE Trans. Fuzzy Syst, Vol. 29, Issue. 4, pp.742-756, 2021.   ",
      "Lu Zhou, Chunpeng Ge, Chunhua Su. “A privacy preserving two-factor authentication protocol for the Bitcoin SPV nodes”, Sci. China Inf. Sci, Vol. 63, Issue. 3, In Press, 2020.",
      "Guangquan Xu, Xiaotong Li, Litao Jiao, Weizhe Wang, Ao Liu, Chunhua Su, Xi Zheng, Shaoying Liu, Xiaochun Cheng. “BAGKD: A Batch Authentication and Group Key Distribution Protocol for VANETs”, IEEE Commun. Mag, Vol. 58, Issue. 7, pp.35-41, 2020.",
      "Na Ruan, Ruoyu Deng, Chunhua Su“GADM Manual fake review detection for O2O commercial platforms”, Comput. Secur. , 88, In Press, 2020.",
      "Guangquan Xu, Bingjiang Guo, Chunhua Su, Xi Zheng, Kaitai Liang, Duncan S. Wong, Hao Wang. “Am I eclipsed? A smart detector of eclipse attacks for Ethereum”, Comput. Secur. , 88, In Press, 2020.",
      "Huiyao Zheng, Jian Shen, Youngju Cho, Chunhua Su, Sangman Moh. “A Novel Structure-Based Data Sharing Scheme in Cloud Computing”, IEICE Trans. Inf. Syst. 103-D(2), pp.222-229 , 2020.",
      "Qiuhua Wang, Mingyang Kang, Guohua Wu, Yizhi Ren, Chunhua Su. “A Practical Secret Key Generation Scheme Based on Wireless Channel Characteristics for 5G Networks”, IEICE Trans. Inf. Syst. 103-D(2) , pp.230-238 , 2020.",
      "Na Ruan, Chunhua Su, Chi Xie. “Securing Cooperative Adaptive Cruise Control in Vehicular Platoons via Cooperative Message Authentication”, IEICE Trans. Inf. Syst. 103-D(2) , pp.256-264 , 2020.",
      "Hiroshi Nomaguchi, Chunhua Su, Atsuko Miyaji. “New Pseudo-Random Number Generator for EPC Gen2”, IEICE Trans. Inf. Syst. 103-D(2): pp.292-298, 2020.",
      "Kuo-Hui Yeh, Chunhua Su, Robert H. Deng, Moti Yung, Miroslaw Kutylowski. “Special issue on security and privacy of blockchain technologies”, Int. J. Inf. Sec, 19(3), pp.243-244 , 2020.",
      "Hung-Yu Chien, Yi-Jui Chen, Guo-Hao Qiu, Jian Fu Liao, Ruo-Wei Hung, Pei-Chih Lin, Xi-An Kou, Mao-Lun Chiang, Chunhua Su. “A MQTT-API-compatible IoT security-enhanced platform”, Int. J. Sens. Networks, Vol. 32, Issue. 1, pp. 54-68, 2020.",
      "Lu Zhou, Chunpeng Ge, Simin Hu, Chunhua Su. “Energy-Efficient and Privacy-Preserving Data Aggregation Algorithm for Wireless Sensor Networks”, IEEE Internet Things J, Vol. 7, Issue. 5, pp. 3948-3957, 2020.",
      "Liming Fang, Changchun Yin, Lu Zhou, Yang Li, Chunhua Su, Jinyue Xia. “A physiological and behavioral feature authentication scheme for medical cloud based on fuzzy-rough core vector machine”, Inf. Sci, Vol.507, pp.143-160, 2020.",
      "Hu Xiong, Yan Wu, Chunhua Su, Kuo-Hui Yeh. “A secure and efficient certificateless batch verification scheme with invalid signature identification for the internet of things. J. Inf. Secur. Appl. Vol.53, pp.102507, 2020.",
      "Jing Zhang, Rong Tan, Chunhua Su, Wen Si. “Design and application of a personal credit information sharing platform based on consortium blockchain”, J. Inf. Secur. Appl, Vol.55, pp.102659, 2020.",
      "Yangguang Tian, Yingjiu Li, Binanda Sengupta, Nan Li, Chunhua Su. “Leakage-resilient biometric-based remote user authentication with fuzzy extractors”, Theor. Comput. Sci, Vol.814, pp. 223-233, 2020.",
      "Lu Zhou, Chunhua Su, Wayne Chiu, Kuo-Hui Yeh. “You Think, Therefore You Are: Transparent Authentication System with Brainwave-Oriented Bio-Features for IoT Networks”, IEEE Trans. Emerg. Top. Comput, Vol. 8, Issue. 2, pp. 303-312, 2020.",
      "Lingjun Zhao, Chunhua Su, Zeyang Dai, Huakun Huang, Shuxue Ding, Xinyi Huang, Zhaoyang Han. “Indoor device-free passive localization with DCNN for location-based services”, J. Supercomput. Vol.76, Issue.11, pp.8432-8449, 2020.",
    ],
  }),
};
</script>
<style scoped>
a:link {
  text-decoration: none;
}
a:active {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
a:visited {
  text-decoration: none;
}
</style>